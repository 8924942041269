<template>
    <div>
      <v-form
         v-on:submit.prevent="DialogAceptar = true"
          ref="form"
          lazy-validation
      >
        <v-card>
        <v-card-title>
          <span class="headline">{{Accion}}</span>
          <v-spacer></v-spacer>
          <span v-if="Item.id != 0" class="headline">ID: {{Item.id}}</span>
        </v-card-title>
        <v-card-text>
          <v-container>
             <v-row>

              <v-col
                cols="12"
                sm="6"
                md="4"
              >
                <v-text-field
                  v-model="Item.nombre"
                  label="Nombre *"
                  hint="Nombre completo del usuario del sistema"
                  persistent-hint
                  required
                ></v-text-field>
              </v-col>

              <v-col
                cols="12"
                sm="6"
                md="4"
              >
                <v-text-field
                  v-model="Item.usuario"
                  label="Usuario *"
                  hint="Número de cedula del usuario registrado"
                  persistent-hint
                  required
                ></v-text-field>
              </v-col>

             <v-col
                cols="12"
                sm="6"
                md="4"
              >
                <v-text-field
                  v-model="Item.celular"
                  label="Celular *"
                  hint="Número de celular del usuario del sistema"
                  persistent-hint
                  required
                ></v-text-field>
              </v-col>

              <v-col
                cols="12"
                sm="6"
                md="4"
              >
                <v-text-field
                  v-model="Item.email"
                  label="Email *"
                  hint="Correo electrónico"
                  persistent-hint
                  required
                ></v-text-field>
              </v-col>

              <v-col
                cols="12"
                sm="6"
                md="4"
              >
               <v-select
                  v-model="Item.perfil"
                  :items="PerfilesGetter.items"
                  item-text="nombre"
                  item-value="id"
                  menu-props="auto"
                  label="Tipo de Usuario"
                  hint="Perfil del sistema"
                  persistent-hint
                  :rules="[v => v >= 0 || 'Item Requerido']"
                  required
                ></v-select>
              </v-col>

              <v-col
                cols="12"
                sm="6"
                md="4"
                v-if="Item.perfil == 3 || Item.perfil == 4"
              >
               <v-select
                  v-model="Item.punto_venta"
                  :items="PuntosDeVentaGetter.items"
                  item-text="razon_social"
                  item-value="id"
                  menu-props="auto"
                  label="Punto De Venta"
                  hint="Punto de venta al que pertenece el usuario."
                  persistent-hint
                  :rules="[v => !!v || 'Item Requerido']"
                  required
                ></v-select>
              </v-col>

              <v-col
                cols="12"
                sm="6"
                md="4"
                v-if="Item.perfil == 3"
              >
               <v-select
                  v-model="Item.encargado"
                  :items="SINO"
                  item-text="titulo"
                  item-value="id"
                  menu-props="auto"
                  label="Encargado - ¿Usuario Principal?"
                  hint="¿Este usuario es el encargado del punto de venta?"
                  persistent-hint
                  :rules="[v => v >= 0 || 'Item Requerido']"
                  required
                ></v-select>
              </v-col>
              
              

             <v-col
                cols="12"
                sm="6"
                md="4"
              >
               <v-select
                  v-model="Item.activo"
                  :items="SINO"
                  item-text="titulo"
                  item-value="id"
                  menu-props="auto"
                  label="¿Usuario está activo?"
                  hint="Indique si el usuario se encuentra activo en el sistema"
                  persistent-hint
                  :rules="[v => v >= 0 || 'Item Requerido']"
                  required
                ></v-select>
              </v-col>

<!--
              <v-col
                cols="12"
                sm="6"
              >
                <v-autocomplete
                  :items="['Nuevo', 'Editar', 'Eliminar', 'Crear Códigos', 'Eliminar Códigos']"
                  label="Permisos"
                  multiple
                ></v-autocomplete>
              </v-col>
-->
              <v-col cols="12">
                <v-textarea
                  auto-grow
                  counter
                  label="Notas"
                  v-model="Item.notas"
                  hint="Escriba comentarios acerca del usuario."
                  persistent-hint
                  required
                ></v-textarea>
              </v-col>
            </v-row>
          </v-container>

          <small>* indica campo requerido</small>

        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="error" @click="Ocultar()">Cerrar</v-btn>
          <v-btn color="primary" type="submit">Guardar</v-btn>
        </v-card-actions>
      </v-card>
      </v-form>

     <v-dialog v-model="DialogAceptar" persistent max-width="380">
      <v-card>
        <v-card-title class="text-h5 grey lighten-2">
          {{Accion}}
        </v-card-title>

        <v-card-text class="mt-4">
          <h2>¿Estas seguro de guardar?</h2>
        </v-card-text>
        
        <v-divider></v-divider>

        <v-card-actions class="mt-5">
          <v-spacer></v-spacer>
          <v-btn color="error" @click="DialogAceptar = false">Cancelar</v-btn>
          <v-btn color="primary" @click="Guardar()">Aceptar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    </div>
</template>

<script>
import { mapState, mapGetters, mapActions} from 'vuex'

export default ({

  props:['Accion','Item','NuevoActivo'],

  watch:{
      Alerta(value){
        if(value.error == false){
          this.Ocultar()
        }
      }
  },

  created (){
    this.ConsultarPuntosDeVenta()
    this.ConsultarPerfiles()
  },

  mounted() {
    if(this.DatosdeUsuario.perfil == 3){
      this.Item.punto_venta = this.DatosdeUsuario.pv
      this.Item.perfil = 4
      this.Item.encargado = 0
    }
    
    
  },

  data: () => ({
    var: null,
    DialogAceptar: false,

    ItemEnviar: {
            id: null,
            nombre: null,
            usuario: null,
            celular: null,
            email: null,
            punto_venta: null,
            encargado: null,
            perfil: null,
            notas: null,
            permisos: '',
            activo: null,
          },
  }),

  methods: {
     ...mapActions(['ConsultarPuntosDeVenta', 'ConsultarPerfiles','NuevoUsuario', 'ConsultarUsuarios']),

    Guardar (){

      this.ItemEnviar.id = this.Item.id
      this.ItemEnviar.nombre = this.Item.nombre
      this.ItemEnviar.usuario = this.Item.usuario
      this.ItemEnviar.celular = this.Item.celular
      this.ItemEnviar.email = this.Item.email
      this.ItemEnviar.punto_venta = this.Item.punto_venta
      this.ItemEnviar.encargado = this.Item.encargado
      this.ItemEnviar.perfil = this.Item.perfil
      this.ItemEnviar.notas = this.Item.notas
      this.ItemEnviar.permisos = this.Item.permisos
      this.ItemEnviar.activo = this.Item.activo
     
      this.NuevoUsuario(this.ItemEnviar)
      setTimeout( () => {this.ConsultarUsuarios()}, 500)
      this.DialogAceptar = false
    },

    Ocultar(){
        this.$emit('NuevoActivo', false)
    }
  },

  computed:{
    ...mapState(['Alerta']),
    ...mapGetters(['SINO','DatosdeUsuario','PuntosDeVentaGetter','PerfilesGetter'])
  }


})
</script>
