<template>
  <div>
    <tabla
          v-if="!UsuariosGetter.error" 
          titulo="Usuarios" 
          :headers = "headers"
          :arreglo = "UsuariosGetter.items"
            />
  </div>
</template>

<script>
import { mapState, mapActions, mapGetters, mapMutations } from 'vuex'
import tabla from '@/components/usuarios/index';

export default {
  components: {
    tabla
  },
  
  created(){
    this.ConsultarUsuarios()
  },

  data: () =>{
    return{
    headers: [
        {
          text: 'ID',
          align: 'start',
          sortable: true,
          value: 'id',
        },
        { text: 'NOMBRE', value: 'nombre' },
        { text: 'PUNTO DE VENTA', value: 'razon_social' },
        { text: 'FECHA REGISTRO', value: 'fecha_registro' },
        { text: 'ACTIVO', value: 'active' },
        { text: 'ACCIONES', value: 'actions', sortable: false },
      ],

    }
  },

  

  methods: {
    ...mapActions(['ConsultarUsuarios']),

  },

  computed:{
      ...mapGetters(['UsuariosGetter'])
  }
}
</script>
